import React, { forwardRef, useCallback } from 'react';
import cn from 'classnames';
import { isValid, set, getYear, getMonth, getDate } from 'date-fns';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import DatePicker from '@/ui/shared/components/Form/DatePicker/DatePicker';
import { YEAR_MONTH_DAY_DASH_FORMAT } from '@/Framework/DateTime/dateFormats';
import { Input, IconType } from '@dealroadshow/uikit';

import styles from './condorDatePicker.scss';
import { IFinalFormFieldInputComponentProps } from '@/ui/shared/components/Form/FinalForm/interfaces';

/**
 * @param {String|Object|null} value
 * @returns {Object|null}
 */
const getSelectedValue = (value) => {
  if (value) {
    const date = new Date(value);

    if (isValid(date)) {
      return date;
    }
  }

  return null;
};

interface IProps {
  meta: any,
  input: any,
  timeZone: string,
  disabled: boolean,
  autoFocus: boolean,
  usePortal?: boolean,
  reduxFormTouchField: (form: string, name: string) => void,
  dateFormat?: string,
  iconClassName: string,
  dataTest?: string,
}

interface CustomInputProps {
  meta: IFinalFormFieldInputComponentProps['meta'],
  name: string,
  iconClassName: string,
  disabled: boolean,
  autoFocus: boolean,
  dataTest: string,
}

const CustomInput = forwardRef((
    { meta, name, iconClassName, disabled, autoFocus, dataTest, ...inputProps }: CustomInputProps,
    ref: React.ForwardedRef<Input>,
) => (
  <Input
    // @ts-ignore
    input={ { ref, ...inputProps } }
    meta={ meta }
    name={ name }
    iconType={ IconType.calendar }
    iconPosition="right"
    iconClassName={ cn(styles.calendarIcon, iconClassName) }
    isNarrow
    disabled={ disabled }
    autoFocus={ autoFocus }
    isClearable={ false }
    dataTest={ `${ dataTest }Input` }
  />
));

const CondorDatePicker = (
  {
    disabled,
    meta,
    input,
    timeZone,
    autoFocus,
    reduxFormTouchField,
    dateFormat = YEAR_MONTH_DAY_DASH_FORMAT,
    usePortal = false,
    iconClassName,
    dataTest = 'condorDatePicker',
  }: IProps,
) => {
  const timeZoneName = timeZone || 'America/New_York';

  const onChange = useCallback(
    (value) => {
      if (value === null) {
        input.onChange(null);
        return;
      }
      const date = new Date(value);
      input.onChange(
        !isValid(date)
          ? null
          : set(utcToZonedDate(timeZoneName), {
            year: getYear(date),
            month: getMonth(date),
            date: getDate(date),
            hours: 0,
            minutes: 0,
          }),
      );
      // Manual touch is needed because it's not working in datepicker.
      reduxFormTouchField(meta.form, input.name);
    },
    [input.onChange, meta.form, input.name],
  );

  return (
    <DatePicker
      autoFocus={ autoFocus }
      customInput={ (
        <CustomInput
          name={ input.name }
          meta={ meta }
          autoFocus={ autoFocus }
          disabled={ disabled }
          dataTest={ dataTest }
          iconClassName={ iconClassName }
        />
      ) }
      disabled={ disabled }
      selected={ getSelectedValue(input.value) }
      dateFormat={ dateFormat }
      onChange={ onChange }
      className={ cn('form-input', styles.wrapper) }
      placeholderText={ dateFormat }
      popperPlacement="bottom-end"
      usePortal={ usePortal }
      dataTest={ dataTest }
    />
  );
};

export default CondorDatePicker;
