import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';

import FinalForm from '@/ui/shared/components/Form/FinalForm';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import ProfileWrapper from '@/condor/ui/common/Layout/ProfileWrapper';
import Link from '@/ui/shared/components/ReactRouter/Link';
import SettingsFormFields from './SettingsFormFields';
import AreYouSureModal from '@/condor/ui/common/Layout/FormWrapper/AreYouSureModal';
import { Spinner, Modal } from '@dealroadshow/uikit';

import { CONDOR_EVERCALL_SETTINGS_FORM } from './constants';
import { CONDOR_EVERCALL_LIST } from '@/condor/ui/common/constants';

import { IAdminCall } from '@/evercall/domain/vo/call/admin/AdminCall';

import { useCallProfileContext } from '@/evercall/application/condor/call/profile/CallProfileContext';
import useCallSettings from '@/evercall/application/condor/call/profile/settings/useCallSettings';
import validate from './validate';

import styles from './settings.scss';
import formStyles from '@/ui/shared/components/Form/form.scss';
import headerStyles from '@/ui/shared/styles/headers.scss';
import callProfileStyles from '../callProfile.scss';

const Settings = () => {
  const [isConferenceIdModalVisible, setIsConferenceIdModalVisible] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const {
    state: {
      item,
      isFetching,
    },
    setItem,
  } = useCallProfileContext();

  const {
    state: {
      isUpdating,
      isUpdateSucceeded,
    },
    updateCallData,
  } = useCallSettings({ setItem });

  const closeConferenceIdModal = () => setIsConferenceIdModalVisible(false);
  const getSubmitPayload = (values: IAdminCall) => (
    {
      ...values,
      participantConferenceId: values.participantConferenceId || null,
      groupName: values.groupName || null,
      groupPath: values.groupPath || null,
      roadshowId: values.roadshowId || null,
      roadshowEntryCode: values.roadshowEntryCode || null,
    }
  );

  const submit = (values: IAdminCall) => {
    if (!!item.participantConferenceId && !values.participantConferenceId) {
      setIsConferenceIdModalVisible(true);
      return null;
    }
    return updateCallData(getSubmitPayload(values));
  };

  const isFormVisible = !isFetching && !isFirstRender;

  useEffect(
    () => {
      setIsFirstRender(false);
    },
    [],
  );

  useEffect(
    () => {
      if (isUpdating && isConferenceIdModalVisible) {
        closeConferenceIdModal();
      }
    },
    [isUpdating],
  );

  return (
    <ProfileWrapper>
      <div className={ cn(headerStyles.isH3, styles.panelHeaderLabel) }>Call Settings</div>
      <Spinner
        isVisible={ !isFormVisible || isUpdating }
        overlay={ isUpdating }
        isFixed={ isUpdating }
      />
      { isFormVisible && (
        <FinalForm<IAdminCall>
          name={ CONDOR_EVERCALL_SETTINGS_FORM }
          dataTest={ CONDOR_EVERCALL_SETTINGS_FORM }
          validate={ validate }
          className={ formStyles.formWrp }
          onSubmit={ submit }
          render={ (
            {
              errors,
              pristine,
              values,
            },
          ) => (
            <>
              <SettingsFormFields
                isUpdating={ isUpdating }
                isUpdateSucceeded={ isUpdateSucceeded }
              />
              <div className={ callProfileStyles.formButtonsContainer }>
                <Button
                  type="submit"
                  variant={ variantTypes.action }
                  title="Save"
                  dataTest="evercallProfileFormSaveButton"
                  disabled={ !isEmpty(errors) || pristine || isUpdating }
                />
                <Link to={ CONDOR_EVERCALL_LIST }>
                  <Button
                    variant={ variantTypes.text }
                    title="Cancel"
                    dataTest="evercallProfileFormFormCancelButton"
                  />
                </Link>
                <AreYouSureModal
                  when={ !pristine && !isUpdating && !isUpdateSucceeded }
                  disableNative={ false }
                />
                <Modal
                  className={ styles.removedConferenceIdModal }
                  onCloseClicked={ closeConferenceIdModal }
                  isVisible={ isConferenceIdModalVisible }
                  title="Are you sure?"
                  dataTest="conferenceIdRemovedModal"
                  footer={ (
                    <>
                      <Button
                        onClick={ () => updateCallData(getSubmitPayload(values)) }
                        variant={ variantTypes.warning }
                        title="Delete"
                        dataTest="deleteButton"
                      />
                      <Button
                        variant={ variantTypes.text }
                        onClick={ closeConferenceIdModal }
                        title="Cancel"
                        dataTest="cancelButton"
                      />
                    </>
                  ) }
                >
                  <div className={ styles.removedConferenceIdWarningContainer }>
                    Removing the Participant Conference ID will erase the Pre-Registration list on the DM Portal.
                    If a new Participant ID is saved, the Pre-Registration list will re-sync with the new ID.
                  </div>
                </Modal>
              </div>
            </>
          ) }
        />
      ) }
    </ProfileWrapper>
  );
};

export default Settings;
