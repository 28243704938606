export const EVENT_FORM = 'eventForm';

export const defaultOption = { value: 'none', label: 'None' };

export enum FieldsNames {
  ROADSHOW_LINK = 'roadshowName',
  ROADSHOW_ID = 'roadshowId',
  DISPLAY_OF_PROFILE = 'profiles',
  DISPLAY_ON = 'displayOn',
  NAME = 'name',
  EXPECTED_DATE = 'expectedPricingDate',
  DESCRIPTION = 'description',
  LOGO = 'logo',
  TILE_IMAGE = 'tileImage',
  TRANSACTION_TYPE = 'transactionType',
  SUBINDUSTRY = 'subindustry',
  SUBSECTOR = 'subsector',
  COUNTRY = 'country',
  DEAL_SIZE = 'dealSize',
  CURRENCY = 'currency',
  TENOR = 'tenor',
  EQUITY_TICKER = 'equityTicker',
  RANKING = 'ranking',
  RATED = 'rated',
}

export enum FieldsLabel {
  ROADSHOW_LINK = 'Link to deal roadshow',
  DISPLAY_OF_PROFILE = 'Display on profiles',
  DISPLAY_ON = 'Display options',
  NAME = 'Event name',
  EXPECTED_DATE = 'expected pricing date',
  DESCRIPTION = 'Event description',
  LOGO = 'Event logo',
  TILE_IMAGE = 'Event tile image',
  TRANSACTION_TYPE = 'Transaction type',
  INDUSTRY = 'Industry & Subindustry',
  SECTOR = 'Sector & Subsector',
  COUNTRY = 'Country',
  DEAL_SIZE = 'Deal size (Actual)',
  CURRENCY = 'Currency',
  TENOR = 'Tenor (Years)',
  EQUITY_TICKER = 'Equity Ticker',
  RANKING = 'Ranking',
  RATED = 'Rated',
}
