import { format } from 'date-fns';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Route from '@/ui/shared/components/ReactRouter/Route';
import pageStyles from '@/ui/shared/styles/page.scss';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import { MONTH_UNARY_DAY_YEAR_DATE_FULLTIME_FORMAT } from '@/Framework/DateTime/dateFormats';

export const getHeaderComponent = (headerFieldValue, matchUrl) => {
  if (headerFieldValue) {
    if (Array.isArray(headerFieldValue)) {
      return headerFieldValue.find((item) => matchUrl === item.whenToRenderPath)?.item;
    }

    if (React.isValidElement(headerFieldValue)) {
      return headerFieldValue;
    }

    return null;
  }

  return null;
};

export const renderSubMenuItem = ({ path, title, dataTest }) => (
  <NavLink
    key={ path }
    to={ path }
    data-test={ dataTest }
    activeClassName={ pageStyles.isActiveNavLink }
  >
    { title }
  </NavLink>
);

export const renderRoute = (route) => (
  <Route
    key={ route.path }
    path={ route.path }
    component={ route.components.content }
  />
);

export const findActiveSubMenuItem = (item, routerParam) => item.path.includes(routerParam);

export const commonCell = (cellProps) => {
  if (!cellProps.cellData) {
    return '-';
  }

  return cellProps.cellData;
};

export const getCondorDateCell = ({ timezone, dateTime }) => {
  const zonedDate = utcToZonedDate(timezone.timeZone, new Date(dateTime));
  return `${ format(zonedDate, MONTH_UNARY_DAY_YEAR_DATE_FULLTIME_FORMAT) } ${ timezone.abbr }`;
};
