// eslint-disable-next-line no-restricted-imports
import { FieldInputProps } from 'react-final-form';
import { Input, Icon, IconType } from '@dealroadshow/uikit';

import { Field } from '@/ui/shared/components/Form/FinalForm';
import RestrictAccess from '../../fields/RestrictAccess';
import openMarketUrl from '@/openMarket/infrastructure/openMarketUrl';
import CopyToClipboard from '@/ui/shared/components/CopyToClipboard';
import { toSlug } from '@/Framework/url/helpers/toSlug';

import { FormFieldName } from '../../constants';

import spaceStyles from '@/ui/shared/styles/helpers/spaces.scss';
import cardStyles from '@/ui/shared/styles/card.scss';
import styles from './details.scss';

const calculateNewCursorPosition = (
  currentCursorPosition: number | null,
  sluggedValue: string,
  value: string,
): number => {
  if (currentCursorPosition === null) {
    return sluggedValue.length;
  }
  return currentCursorPosition + (sluggedValue.length - value.length);
};

const Details = () => {
  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>, input: FieldInputProps<any>) => {
    if (!e.target?.value) {
      input.onChange('');
      return;
    }

    const newValue = toSlug(e.target?.value, { trim: false });
    const newCursorPosition = calculateNewCursorPosition(e.target.selectionStart, newValue, e.target.value);

    input.onChange(newValue);

    requestAnimationFrame(() => {
      e.target.setSelectionRange(newCursorPosition, newCursorPosition);
    });
  };

  return (
    <div
      className={ cardStyles.cardInnerHorizontal }
      data-test="detailsSection"
    >
      <Field
        name={ FormFieldName.NAME }
        dataTest={ FormFieldName.NAME }
        component={ Input }
        label="Profile Name"
        placeholder="Profile Name"
        className={ styles.formInputWrp }
      />
      <Field
        name={ FormFieldName.URL }
        render={ ({ input, meta }) => (
          <>
            <div className={ styles.urlField }>
              <Input
                label="Profile URL"
                placeholder="Profile URL"
                dataTest={ FormFieldName.URL }
                name={ FormFieldName.URL }
                meta={ meta }
                formFieldClassName={ spaceStyles.mbn }
                input={ {
                  ...input,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => handleUrlChange(e, input),
                } }
              />
              <div className={ styles.urlCopy }>
                <CopyToClipboard
                  dataTest="copyUrlButton"
                  content={ `${ openMarketUrl.getUrl(input.value) }` }
                >
                  <Icon
                    type={ IconType.copy }
                    className={ styles.urlCopyIcon }
                  />
                </CopyToClipboard>
              </div>
              <div
                className={ styles.url }
                data-test="urlString"
              >
                { `${ openMarketUrl.getUrl(input.value) }` }
              </div>
            </div>
          </>
        ) }
      />
      <Field
        name={ FormFieldName.RESTRICT_ACCESS }
        component={ RestrictAccess }
      />
    </div>
  );
};

export default Details;
