import { format } from 'date-fns';
import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';
import cn from 'classnames';
import { Field } from 'redux-form';
import React, { Component } from 'react';
import uuidValidate from 'uuid-validate';
import { Input, ImgFetching, Tooltip, Modal, DataTable, dataTableStyles, Icon, IconType, StatusIndicator } from '@dealroadshow/uikit';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import Textarea from '@/ui/shared/components/Form/Input/Textarea';
import { NotificationManager } from '@/ui/shared/components/Notification';
import * as constants from '@/condor/ui/common/constants';
import Upload from '@/ui/shared/modules/upload';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import { messageCodes } from '@/Framework/Message/messages';
import files from '@/Framework/url/filesUrl';
import alignStyles from '@/ui/shared/styles/helpers/align.scss';
import formStyles from '@/ui/shared/components/Form/form.scss';
import styles from './styles.scss';
import { isEnterKey } from '@/Framework/browser/checkPressedKey';

const { UploadRelatedRoadshowLogoContainer } = Upload.containers;

interface IProps {
  logo: any,
  onChangeLogo: (name: string, url: string | null) => void,
  initUploadRelatedRoadshowLogo: (payload: { url: string }) => void,
  uploadRelatedRoadshowLogoCancel: (id: string) => void,
  toggleDeleteRelatedRoadshow: (payload: any) => void,
  getRoadshowForCustomModal: (id: string) => void,
  resetRoadshowForCustomModal: () => void,
  newRoadshow: any,
  fields: any,
  roadshowId: string,
  uploadClear: () => void,
  items: any[],
}

class RelatedRoadshows extends Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      modalEditVisibleRowIndex: null,
      modalDeleteVisibleRowIndex: null,
      errors: null,
      roadshowInput: '',
    };
    this.cellActionCallback = this.cellActionCallback.bind(this);
    this.addRoadshowToRelated = this.addRoadshowToRelated.bind(this);
    this.uploadOrder = this.uploadOrder.bind(this);
    this.getMetaForError = this.getMetaForError.bind(this);
    this.removeError = this.removeError.bind(this);
    this.applyDeleteModal = this.applyDeleteModal.bind(this);
    this.hideDeleteModal = this.hideDeleteModal.bind(this);
    this.handleRoadshowIdChange = this.handleRoadshowIdChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.newRoadshow.id !== prevProps.newRoadshow.id && Object.keys(this.props.newRoadshow).length) {
      this.props.fields.push({
        id: this.props.newRoadshow.id,
        name: this.props.newRoadshow.name,
        status: 'pending',
        logoUrl: '',
        text: '',
        deletedAt: null,
      });
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        roadshowInput: '',
      });
      this.props.resetRoadshowForCustomModal();
    }
  }

  getMetaForError() {
    if (this.state.errors !== null) {
      return {
        touched: true,
        error: (<div>{ this.state.errors }</div>),
        warning: false,
      };
    }
    return {};
  }

  getEditModal() {
    return (
      <Modal
        onCloseClicked={ () => this.hideEditModal() }
        isVisible={ this.state.modalEditVisibleRowIndex !== null }
        showCloseButton={ false }
        closeOnEsc={ false }
        title="Update Roadshow Details"
        dataTest="updateRoadshowDetailsModal"
        ref={ (ref) => {
          // @ts-ignore
          this.modal = ref;
        } }
        footer={ (
          <>
            <Button
              variant={ variantTypes.success }
              // @ts-ignore
              value="save"
              onClick={ () => this.applyEditModalUpload(this.state.modalEditVisibleRowIndex) }
              dataTest="applyRoadshowDetailModalButton"
              title="Apply"
            />
            <Button
              variant={ variantTypes.secondary }
              onClick={ () => this.hideEditModal() }
              dataTest="cancelRoadshowDetailModalButton"
              title="Cancel"
            />
          </>
        ) }
      >
        <Field
          label="Display Text"
          name={ `relatedRoadshows[${ this.state.modalEditVisibleRowIndex }]text` }
          component={ Textarea }
          dataTest={ `relatedRoadshows[${ this.state.modalEditVisibleRowIndex }]Textarea` }
        />
        <div>
          <div className={ formStyles.formLabel }>Logo</div>
          <UploadRelatedRoadshowLogoContainer />
        </div>
      </Modal>
    );
  }

  getDeleteModal() {
    let relatedRoadshow = this.props.fields.get(this.state.modalDeleteVisibleRowIndex);
    let title = `${ relatedRoadshow.deletedAt ? 'Restore' : 'Delete' } ${ relatedRoadshow.id } Related Roadshow?`;
    return (
      <Modal
        onCloseClicked={ () => this.hideDeleteModal() }
        isVisible={ this.state.modalDeleteVisibleRowIndex !== null }
        title={ title }
        dataTest="deleteRestoreModal"
        footer={ (
          <div>
            <Button
              variant={ variantTypes.success }
              // @ts-ignore
              value="Delete"
              // @ts-ignore
              onClick={ () => this.applyDeleteModal(this.state.modalDeleteVisibleRowIndex) }
              title={ relatedRoadshow.deletedAt ? 'Restore' : 'Delete' }
              dataTest={ `${ relatedRoadshow.deletedAt ? 'restore' : 'delete' }Button` }
            />
            <Button
              variant={ variantTypes.secondary }
              onClick={ () => this.hideDeleteModal() }
              dataTest="cancelRestoreModalButton"
              title="Cancel"
            />
          </div>
        ) }
      />
    );
  }

  applyDeleteModal() {
    let relatedRoadshow = this.props.fields.get(this.state.modalDeleteVisibleRowIndex);
    if (!Object.prototype.hasOwnProperty.call(relatedRoadshow, 'status')) {
      this.props.toggleDeleteRelatedRoadshow({
        parentId: this.props.roadshowId,
        childId: relatedRoadshow.id,
        type: 'delete',
        value: !relatedRoadshow.deletedAt,
      });
    } else {
      this.props.fields.remove(this.state.modalDeleteVisibleRowIndex);
    }

    this.hideDeleteModal();
  }

  hideDeleteModal() {
    this.setState({
      modalDeleteVisibleRowIndex: null,
    });
  }

  showDeleteModal(index) {
    this.setState({
      modalDeleteVisibleRowIndex: index,
    });
  }

  cellActionCallback({ row, rowIndex }) {
    return (
      <div className={ styles.actionsCell }>
        <Tooltip content="Edit">
          <Icon
            type={ IconType.pencil }
            onClick={ () => {
              let currentRoadshow = this.props.fields.get(rowIndex);
              if (currentRoadshow.logoUrl) {
                this.props.initUploadRelatedRoadshowLogo({ url: currentRoadshow.logoUrl });
              }
              this.showEditModal(rowIndex);
            } }
          />
        </Tooltip>
        <Tooltip content={ `${ row.deletedAt ? 'Restore' : 'Delete' } Related Roadshow` }>
          <Icon
            type={ row.deletedAt ? IconType.refresh : IconType.trash }
            onClick={ () => {
              this.showDeleteModal(rowIndex);
            } }
          />
        </Tooltip>
      </div>
    );
  }

  showEditModal(index) {
    this.setState({
      modalEditVisibleRowIndex: index,
    });
  }

  hideEditModal() {
    this.setState({
      modalEditVisibleRowIndex: null,
    });
    this.props.uploadClear();
  }

  applyEditModalUpload(index) {
    if (!this.props.logo.canSave) {
      NotificationManager.warning(getMessage(messageCodes.CONDOR_FILE_UPLOADING, { name: this.props.logo.name }));
    } else {
      this.props.onChangeLogo(`relatedRoadshows[${ index }]logoUrl`, this.props.logo.url || null);
      this.props.onChangeLogo(`relatedRoadshows[${ index }]fileName`, this.props.logo?.uploadFile?.name || null);
      this.props.uploadRelatedRoadshowLogoCancel(this.props.logo.process.uuid);
      this.hideEditModal();
    }
  }

  /**
   * @param {Number} oldIndex
   * @param {Number} newIndex
   */
  uploadOrder(oldIndex, newIndex) {
    this.props.fields.move(oldIndex, newIndex);
  }

  addRoadshowToRelated() {
    let newRoadshowId = this.state.roadshowInput;
    let newRoadshowIdValidate = uuidValidate(newRoadshowId);

    if (!newRoadshowIdValidate) {
      this.setState({
        errors: `${ newRoadshowId } is invalid roadshow id`,
      });
    }

    if (newRoadshowIdValidate) {
      let duplicate = this.props.fields.getAll().filter((relatedRoadshow) => relatedRoadshow.id === newRoadshowId);
      if (duplicate.length) {
        this.setState({
          errors: `${ newRoadshowId } is already in Related Roadshow table`,
        });
      } else {
        this.props.getRoadshowForCustomModal(newRoadshowId);
      }
    }
  }

  removeError() {
    this.setState({
      errors: null,
    });
  }

  cellPublishedCallback({ row }) {
    let className = 'published';
    let title = 'saved';
    if (row?.status) {
      className = 'unpublished';
      title = 'New';
    }
    return (
      <Tooltip className={ alignStyles.flexCenter } content={ `${ row?.status ? 'New' : 'saved' }` }>
        <StatusIndicator status={ className } title={ title } />
      </Tooltip>
    );
  }

  handleRoadshowIdChange(event) {
    this.setState({
      roadshowInput: event.target.value,
    });
  }

  render() {
    const tableColumns = [
      {
        name: 'name',
        title: 'Roadshow Name',
        className: cn(alignStyles.alignLeft, styles.nameCell),
        cellCallback: ({ row }) => row.name,
      },
      {
        name: 'status',
        width: '7%',
        title: 'Status',
        className: alignStyles.alignCenter,
        cellCallback: this.cellPublishedCallback,
      },
      {
        name: 'logoUrl',
        width: '13%',
        title: 'Logo',
        sortable: true,
        cellCallback: ({ row }) => !!row.logoUrl && (
          <ImgFetching src={ files.getFileUrl(row.logoUrl) } />
        ),
      },
      {
        name: 'text',
        width: 277,
        title: 'Display Text',
        sortable: true,
        cellCallback: ({ row }) => row.text,
      },
      {
        name: 'deletedAt',
        width: '15%',
        title: 'Deleted',
        cellCallback: ({ row }) => (
          <div>
            { row.deletedAt
              ? format(getDateFromUnixTime(row.deletedAt), constants.CONDOR_DEAL_FILES_DATE_TIME_FORMAT)
              : '' }
          </div>
        ),
        sortable: true,
      },
      {
        name: 'action',
        minWidth: 72,
        title: 'Actions',
        className: cn(alignStyles.alignCenter),
        cellCallback: this.cellActionCallback,
      },
      {
        name: 'dragAndDrop',
        width: '5%',
        className: dataTableStyles.draggableCol,
        isDraggable: true,
        title: '',
      },
    ];
    return (
      <div>
        <DataTable
          isDraggable
          // @ts-ignore
          draggableIdName="id"
          dataTest="relatedRoadshowsDataTable"
          columns={ tableColumns }
          data={ this.props.items || [] }
          orderCallback={ this.uploadOrder }
          containerClassName={ styles.table }
        />
        <div className={ styles.roadshowInputWrapper }>
          { /* @ts-ignore */ }
          <Input
            type="text"
            name="addRoadshow"
            placeholder="Enter Roadshow ID"
            data-lpignore="true"
            dataTest="relatedRoadshowsInput"
            onFocus={ this.removeError }
            onKeyPress={ (event) => {
              // @ts-ignore
              if (isEnterKey(event)) {
                this.addRoadshowToRelated();
              }
            } }
            meta={ this.getMetaForError() }
            onChange={ this.handleRoadshowIdChange }
            value={ this.state.roadshowInput }
            isNarrow
            className={ styles.roadshowInput }
          />
          <Button
            variant={ variantTypes.action }
            onClick={ this.addRoadshowToRelated }
            title="Add Roadshow"
            dataTest="addRoadshowButton"
            className={ styles.roadshowSubmit }
          />
        </div>

        { this.state.modalEditVisibleRowIndex !== null && this.getEditModal() }
        { this.state.modalDeleteVisibleRowIndex !== null && this.getDeleteModal() }
      </div>
    );
  }
}

export default RelatedRoadshows;
