import React from 'react';
import cn from 'classnames';

import { useApproveDenyFormContext } from '@/condor/application/FinsightManager/ManagePlans/ApproveDenyFormContext';
import { LastTableCell } from '@/ui/shared/components/Tables/TableWrapper';
import TableRowActions from '@/condor/ui/common/Layout/TableRowActions';
import { IconType, ICellProps as IDataTableCellProps } from '@dealroadshow/uikit';
import styles from '@/condor/ui/components/FinsightManager/ManagePlans/Requests/requests.scss';

import { getCellDisplayDateFromString } from '@/condor/ui/common/Layout/helpers';

import {
  RequestSubmitType,
} from '@/condor/application/FinsightManager/ManagePlans/constants';

import { UserPlanRequestStatus } from '@/finsight/application/User/UserPlan/helpers';
import { IManagePlansRequestItem } from '@/condor/domain/vo/FinsightManager/ManagePlans';

const RequestedAtCell = (cellProps: IDataTableCellProps<IManagePlansRequestItem>) => {
  const { setSelectedSubmitData } = useApproveDenyFormContext();
  const onApproveClick = () => setSelectedSubmitData(
    { typeSubmit: RequestSubmitType.APPROVE, cellData: cellProps.row });

  const onDenyClick = () => setSelectedSubmitData(
    { typeSubmit: RequestSubmitType.DENY, cellData: cellProps.row });

  const isDisabled = cellProps.row.requestStatus !== UserPlanRequestStatus.Pending;
  return (
    <LastTableCell
      childrenToShowOnHover={ (
        <TableRowActions
          actions={
            [
              {
                onClick: onApproveClick,
                disabled: isDisabled,
                tooltipContent: isDisabled ? 'Manage User through the Subscribers page' : 'Approve Request',
                iconType: IconType.checkCircle,
                className: styles.actionContainer,
                iconClassName: cn({ [styles.iconApprove]: !isDisabled }),
                dataTest: 'approveRequest',
              },
              {
                onClick: onDenyClick,
                disabled: isDisabled,
                tooltipContent: isDisabled ? 'Manage User through the Subscribers page' : 'Deny Request',
                iconType: IconType.deny,
                iconClassName: cn({ [styles.iconDeny]: !isDisabled }),
                dataTest: 'denyRequest',
              },
            ]
          }
        />
      ) }
    >
      { getCellDisplayDateFromString(cellProps.cellData) }
    </LastTableCell>
  );
};

export default RequestedAtCell;
