import EventFormContextProvider, { useEventFormContext } from './EventFormContext';
import UploadImageInputContextProvider from '@/ui/shared/modules/upload/components/UploadImageInput/UploadImageInputContext';
import FinalForm from '@/ui/shared/components/Form/FinalForm';
import EventFields from './EventFields';
import validation from './validation';
import { EVENT_FORM } from './constants';
import { IUseEventFormProps } from './interfaces';

const EventForm = () => {
  const {
    handleSubmit,
    initialValues,
  } = useEventFormContext();

  return (
    <FinalForm
      name={ EVENT_FORM }
      dataTest={ EVENT_FORM }
      render={ EventFields }
      onSubmit={ handleSubmit }
      validate={ validation }
      initialValues={ initialValues }
    />
  );
};

export default (props: IUseEventFormProps) => (
  <UploadImageInputContextProvider>
    <EventFormContextProvider { ...props }>
      <EventForm />
    </EventFormContextProvider>
  </UploadImageInputContextProvider>
);
