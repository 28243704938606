import React, { createElement, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { Switch, useHistory } from 'react-router-dom';

import { findActiveSubMenuItem, getHeaderComponent, renderRoute, renderSubMenuItem } from '../helpers';

import ExportToExcelBIButton from './BIContacts/ExportToExcelButtonContainer';
import ExportToExcelTraceButton from './TraceContacts/ExportToExcelButtonContainer';
import BIContacts from './BIContacts';
import TraceContacts from './TraceContacts';
import * as constants from '@/condor/ui/common/constants';

import styles from './analytics.scss';
import headerStyles from '@/ui/shared/styles/headers.scss';
import pageStyles from '@/ui/shared/styles/page.scss';
import spacesStyles from '@/ui/shared/styles/helpers/spaces.scss';

const SUB_MENU_ITEMS = [
  {
    title: 'B&I',
    path: constants.CONDOR_FINSIGHT_MANAGER_ANALYTICS_BI_URL,
    dataTest: 'analyticsBiTab',
    components: {
      header: [
        {
          item: ExportToExcelBIButton,
          whenToRenderPath: constants.CONDOR_FINSIGHT_MANAGER_ANALYTICS_BI_URL,
        },
        {
          item: ExportToExcelBIButton,
          whenToRenderPath: constants.CONDOR_FINSIGHT_MANAGER_ANALYTICS_TRACE_URL,
        },
      ],
      content: BIContacts,
    },
  },
  {
    title: 'Trace',
    path: constants.CONDOR_FINSIGHT_MANAGER_ANALYTICS_TRACE_URL,
    dataTest: 'analyticsTraceTab',
    components: {
      header: [
        {
          item: ExportToExcelTraceButton,
          whenToRenderPath: constants.CONDOR_FINSIGHT_MANAGER_ANALYTICS_BI_URL,
        },
        {
          item: ExportToExcelTraceButton,
          whenToRenderPath: constants.CONDOR_FINSIGHT_MANAGER_ANALYTICS_TRACE_URL,
        },
      ],
      content: TraceContacts,
    },
  },
];

interface IProps {
  onUnmount: () => void,
}

const Analytics = ({ onUnmount }: IProps) => {
  const { location: { pathname } } = useHistory();
  useEffect(() => onUnmount, [onUnmount]);

  const activeSubItem = useMemo(
    () => SUB_MENU_ITEMS.find((item) => findActiveSubMenuItem(item, pathname)),
    [pathname],
  );

  const header = activeSubItem?.components?.header;

  const headerComponent = useMemo(
    () => getHeaderComponent(header, pathname),
    [header, pathname],
  );

  return (
    <>
      <div className={ cn(pageStyles.pageHeaderWithActions, spacesStyles.mbm) }>
        <div
          className={ cn(pageStyles.pageHeaderTitle, headerStyles.isH2) }
          data-test="pageHeaderTitle"
        >
          Analytics
        </div>
        <div className={ pageStyles.pageHeaderActionsWrapper }>
          <div className={ styles.actions }>
            { headerComponent ? createElement(headerComponent) : headerComponent }
          </div>
        </div>
      </div>
      <div className={ cn(pageStyles.pageNavigation, styles.navigation) }>
        <div className={ cn(pageStyles.pageNavigationLinks, styles.links) }>
          { SUB_MENU_ITEMS.map(renderSubMenuItem) }
        </div>
      </div>
      <Switch>
        { SUB_MENU_ITEMS.map(renderRoute) }
      </Switch>
    </>
  );
};

export default Analytics;
