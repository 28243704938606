import React, { useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';

import FinalForm from '@/ui/shared/components/Form/FinalForm';
import UploadImageInputContextProvider, {
  useUploadImageInputContext,
} from '@/ui/shared/modules/upload/components/UploadImageInput/UploadImageInputContext';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import ProfileWrapper from '@/condor/ui/common/Layout/ProfileWrapper';
import Link from '@/ui/shared/components/ReactRouter/Link';
import AreYouSureModal from '@/condor/ui/common/Layout/FormWrapper/AreYouSureModal';
import { Spinner } from '@dealroadshow/uikit';
import CustomRegistrationFormFields from './CustomRegistrationFormFields';

import { ICustomRegistration } from '@/evercall/domain/vo/call/admin/CustomRegistration';

import { CONDOR_EVERCALL_CUSTOM_REGISTRATION_FORM } from './constants';
import { CONDOR_EVERCALL_LIST } from '@/condor/ui/common/constants';

import CustomRegistrationContext, {
  useCustomRegistrationContext,
} from '@/evercall/application/condor/call/profile/customRegistration/CustomRegistrationContext';
import validate from './validate';
import stripTags from '@/Framework/browser/stripTags';
import { addHttpToUrl } from '@/Framework/url/helpers';

import callProfileStyles from '../callProfile.scss';
import formStyles from '@/ui/shared/components/Form/form.scss';

const CustomRegistration = () => {
  const isFirstRender = useRef(true);

  const {
    state: {
      isFetching,
      isUpdating,
    },
    initCustomRegistration,
    updateSettings,
  } = useCustomRegistrationContext();
  const { isAllUploadCanSave } = useUploadImageInputContext();

  useEffect(
    () => {
      isFirstRender.current = false;
      initCustomRegistration();
    },
    [],
  );

  const submit = (formData: ICustomRegistration) => {
    const {
      customDetailsSectionSubfields,
      registrationButtonText,
      confirmationTitleText,
      customDetailsSectionName,
      ...restFormData
    } = formData;
    const getEditorValue = (value: string | null) => {
      if (!value) {
        return '';
      }
      return stripTags(value) // check if plain text exists
        ? value
        : '';
    };
    return updateSettings(
      {
        ...restFormData,
        registrationButtonText: registrationButtonText || '',
        confirmationTitleText: confirmationTitleText || '',
        registrationCustomMessage: getEditorValue(formData.registrationCustomMessage),
        confirmationDescription: getEditorValue(formData.confirmationDescription),
        customDetailsSectionName: customDetailsSectionName || '',
        customDetailsSectionSubfields: customDetailsSectionSubfields
          .filter((subfield) => (subfield.title && subfield.text))
          .map(({ text, title, hyperlinkText }) => ({
            text: text || '',
            title: title || '',
            hyperlinkText: addHttpToUrl(hyperlinkText),
          })),
      },
    );
  };

  const isFormVisible = !isFetching && !isFirstRender.current;

  return (
    <ProfileWrapper>
      <Spinner
        isVisible={ !isFormVisible || isUpdating }
        overlay={ isUpdating }
        isFixed={ isUpdating }
      />
      { isFormVisible && (
        <FinalForm
          name={ CONDOR_EVERCALL_CUSTOM_REGISTRATION_FORM }
          dataTest={ CONDOR_EVERCALL_CUSTOM_REGISTRATION_FORM }
          validate={ validate }
          className={ formStyles.formWrp }
          onSubmit={ submit }
          render={ (
            {
              errors,
              pristine,
              validating,
            },
          ) => (
            <>
              <CustomRegistrationFormFields />
              <div className={ callProfileStyles.formButtonsContainer }>
                <Button
                  type="submit"
                  variant={ variantTypes.action }
                  title="Save"
                  dataTest="evercallCustomRegistrationFormSaveButton"
                  disabled={ !isEmpty(errors) || !isAllUploadCanSave || pristine || isUpdating || validating }
                />
                <Link to={ CONDOR_EVERCALL_LIST }>
                  <Button
                    variant={ variantTypes.text }
                    title="Cancel"
                    dataTest="evercallCustomRegistrationFormCancelButton"
                  />
                </Link>
                <AreYouSureModal
                  when={ !pristine && !isUpdating }
                  disableNative={ false }
                />
              </div>
            </>
          ) }
        />
      ) }
    </ProfileWrapper>
  );
};

export default () => (
  <UploadImageInputContextProvider>
    <CustomRegistrationContext>
      <CustomRegistration />
    </CustomRegistrationContext>
  </UploadImageInputContextProvider>
);
