import React from 'react';

import TableWrapper from '@/ui/shared/components/Tables/TableWrapper';
import useReduxSelector from '@/Framework/hooks/useReduxSelector';
import SubscribersTable from './SubscribersTable/SubscribersTableContainer';
import selectors from '@/condor/application/actions/finsight/subscriptions/metadata/selectors';

import SubscribersTablePaginator from './SubscribersTable/SubscribersTablePaginatorContainer';
import SearchInputContainer from './SearchInputContainer';
import ManageSubscriptionsModal from './ManageSubscriptionsModal';

import cardStyles from '@/ui/shared/styles/card.scss';
import styles from './subscribersList.scss';

const SubscribersList = () => {
  const isManageSubscriptionsModalVisible = useReduxSelector(selectors.isManageSubscriptionsModalVisible);

  return (
    <TableWrapper
      label="Finsight Email Alert Subscribers"
      className={ styles.tableWrapper }
      filterComponent={ (
        <SearchInputContainer
          className={ styles.searchFilterInput }
          inputClassName={ styles.inputClassName }
          placeholder="Filter by Email"
          isNarrow
          isClearable
          dataTest="subscribersListSearchInput"
        />
      ) }
    >
      <SubscribersTable />
      <SubscribersTablePaginator className={ cardStyles.cardInner } />
      { isManageSubscriptionsModalVisible && (
        <ManageSubscriptionsModal />
      ) }
    </TableWrapper>
  );
};

export default SubscribersList;
