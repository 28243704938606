import React, { useEffect, useState } from 'react';
import Select from '@/ui/shared/components/Form/Select/legacy';
import selectors from '@/condor/application/actions/finsight/company/deals/collection/selectors';
import useReduxSelector from '@/Framework/hooks/useReduxSelector';
import { batch, useDispatch } from 'react-redux';
import {
  getCondorFinsightCompanyDealsByFilter,
  paginate,
  setCondorDealsListFiltersProducts,
} from '@/condor/application/actions/finsight/company/deals/collection/actions';

import style from './dealsList.scss';

interface IProduct {
  value: string,
  label: string,
}

interface IReduxProps {
  listProducts: IProduct[],
  selectProducts: IProduct[],
}

const stateSelector = (state): IReduxProps => ({
  listProducts: selectors.getOptions(state).products,
  selectProducts: selectors.getFilters(state).products,
});

const ProductsFilter = () => {
  const dispatch = useDispatch();
  const { listProducts, selectProducts } = useReduxSelector(stateSelector);
  const [values, setValues] = useState(selectProducts.length === 0 ? listProducts : selectProducts);

  useEffect(() => {
    setValues(selectProducts.length === 0 ? listProducts : selectProducts);
  }, [listProducts, selectProducts]);

  useEffect(() => {
    batch(() => {
      dispatch(paginate(1));
      dispatch(getCondorFinsightCompanyDealsByFilter());
    });
  }, [selectProducts]);

  const onChange = (products) => {
    if (products.length > 0) {
      dispatch(setCondorDealsListFiltersProducts(products));
    }
  };

  return (
    <Select
      name="filterByProducts"
      dataTest="dealsListFilterByProducts"
      placeholder="Select product(s)"
      options={ listProducts }
      value={ values }
      isMulti
      hideSelectedOptions={ false }
      closeMenuOnSelect={ false }
      onChange={ onChange }
      formFieldClassName={ style.productsFilter }
    />
  );
};

export default ProductsFilter;
