import React from 'react';
import cn from 'classnames';

import TableRowFields from '@/condor/ui/common/Layout/TableRowFields';
import CondorDatePicker from '@/condor/ui/common/CondorDatePicker';
import { CheckboxInput, Input } from '@dealroadshow/uikit';

import { DEAL_PROFILE_FIELDS } from '../constants';

import styles from '../dealProfile.scss';
import spacesStyles from '@/ui/shared/styles/helpers/spaces.scss';
import alignStyles from '@/ui/shared/styles/helpers/align.scss';
import layoutStyles from '@/condor/ui/common/Layout/layout.scss';

interface IProps {
  isProductAbs: boolean,
}

const AdditionalDealProperties = ({ isProductAbs }: IProps) => (
  <div className={ styles.panelContent }>
    { isProductAbs && (
      <>
        <div className={ styles.smallTitle }>ABS</div>
        <TableRowFields
          fields={
            [
              {
                ...DEAL_PROFILE_FIELDS.CUT_OFF_BALANCE,
                component: Input,
                size: 'small',
              },
              {
                ...DEAL_PROFILE_FIELDS.EXP_PRICING_DATE,
                component: CondorDatePicker,
                cellClassName: styles.smallInputsContainer,
                width: 160,
                maxWidth: 160,
              },
              {
                ...DEAL_PROFILE_FIELDS.EXP_RATINGS_DATE,
                component: CondorDatePicker,
                cellClassName: styles.smallInputsContainer,
                width: 160,
                maxWidth: 160,
              },
              {
                ...DEAL_PROFILE_FIELDS.EXP_SETTLEMENT_DATE,
                component: CondorDatePicker,
                cellClassName: styles.smallInputsContainer,
                width: 160,
                maxWidth: 160,
              },
              {
                ...DEAL_PROFILE_FIELDS.PRICING_SPEED,
                component: Input,
                size: 'small',
              },
            ]
          }
        />
        <div className={ spacesStyles.ptl } />
        <div className={ styles.smallTitle }>CMBS</div>
        <TableRowFields
          fields={
            [
              {
                ...DEAL_PROFILE_FIELDS.NUMBER_OF_LOANS,
                size: 'small',
                component: Input,
              },
              {
                ...DEAL_PROFILE_FIELDS.NUMBER_OF_PROPERTIES,
                size: 'small',
                component: Input,
              },
              {
                ...DEAL_PROFILE_FIELDS.TOP_10_LOANS,
                size: 'small',
                component: Input,
              },
              {
                ...DEAL_PROFILE_FIELDS.WA_CUT_OFF_LTV,
                size: 'small',
                component: Input,
              },
              {
                ...DEAL_PROFILE_FIELDS.WA_MATURITY_LTV,
                size: 'small',
                component: Input,
              },
            ]
          }
        />
        <div className={ spacesStyles.mbl } />
        <TableRowFields
          fields={
            [
              {
                ...DEAL_PROFILE_FIELDS.WA_REM_AMRT,
                size: 'small',
                component: Input,
              },
              {
                ...DEAL_PROFILE_FIELDS.WA_SEASONING_MONTHS,
                size: 'small',
                component: Input,
              },
              {
                ...DEAL_PROFILE_FIELDS.WA_TERM_TO_MAT,
                size: 'small',
                component: Input,
              },
              {
                ...DEAL_PROFILE_FIELDS.WA_UW_DSCR,
                size: 'small',
                component: Input,
              },
              {
                ...DEAL_PROFILE_FIELDS.WA_UW_NOI_DEBT_YIELD,
                size: 'small',
                component: Input,
              },
            ]
          }
        />
        <div className={ spacesStyles.ptl } />
        <div className={ styles.smallTitle }>CLO</div>
        <TableRowFields
          fields={
            [
              {
                ...DEAL_PROFILE_FIELDS.REINVESTMENT_PERIOD,
                size: 'small',
                component: Input,
              },
              {
                ...DEAL_PROFILE_FIELDS.REINVESTMENT_PERIOD_END_DATE,
                component: CondorDatePicker,
                cellClassName: styles.smallInputsContainer,
                width: 160,
                maxWidth: 160,
              },
              {
                ...DEAL_PROFILE_FIELDS.REFINANCING,
                component: CheckboxInput,
                cellClassName: cn(styles.smallInputsContainer, alignStyles.alignCenter),
                type: 'checkbox',
                width: 75,
                maxWidth: 75,
              },
              {
                ...DEAL_PROFILE_FIELDS.RESET,
                component: CheckboxInput,
                cellClassName: cn(styles.smallInputsContainer, alignStyles.alignCenter),
                type: 'checkbox',
                width: 75,
                maxWidth: 75,
              },
              {
                ...DEAL_PROFILE_FIELDS.WAL_TEST_PD,
                size: 'small',
                component: Input,
              },
              {
                ...DEAL_PROFILE_FIELDS.WAL_TEST_END_DATE,
                component: CondorDatePicker,
                cellClassName: styles.smallInputsContainer,
                width: 160,
                maxWidth: 160,
              },
            ]
          }
        />
        <div className={ spacesStyles.ptl } />
      </>
    ) }
    <div className={ styles.smallTitle }>Other</div>
    <TableRowFields
      fields={
        [
          {
            ...DEAL_PROFILE_FIELDS.MIN_DENOMINATION,
            component: Input,
            size: 'small',
            className: layoutStyles.inputField,
          },
        ]
      }
    />
  </div>
);

export default AdditionalDealProperties;
