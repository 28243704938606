import React, { useState } from 'react';
import cn from 'classnames';

import { useEnhancedTaggingRoadshowsContext } from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingRoadshowsContext';
import { Input, IconType } from '@dealroadshow/uikit';
import ActionButton from '@/ui/shared/components/Button/ActionButton';
import { variantTypes as ActionButtonVariantTypes } from '@/ui/shared/components/Button/ActionButton/constants';

import cardStyles from '@/ui/shared/styles/card.scss';
import styles from './roadshows.scss';

const BULK_EDIT_RESTRICTION_COUNT = 200;

const RoadshowsHeader = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const {
    setIsBulkModalVisible,
    editableRowId,
    checkedRoadshows,
    debouncedSearch,
  } = useEnhancedTaggingRoadshowsContext();

  const handleBulkEditingModal = () => {
    setIsBulkModalVisible(true);
  };

  const onSearchChange = (event) => {
    setSearchValue(event.target.value);
    debouncedSearch(event.target.value);
  };

  const isShownBulkEditButton = checkedRoadshows.length > 1 && !editableRowId;
  const isDisabledBulkEditButton = checkedRoadshows.length > BULK_EDIT_RESTRICTION_COUNT;

  return (
    <div className={ cn(cardStyles.cardHeader, styles.cardHeader) }>
      <div className={ cn(cardStyles.cardHeaderTitle, styles.cardHeaderTitle) }>
        <h3>Enhanced Tagging</h3>
        { isShownBulkEditButton && (
          <div className={ styles.headerTitleActions }>
            <ActionButton
              title={
                isDisabledBulkEditButton
                  ? `This feature does not allow bulk editing for more than ${ BULK_EDIT_RESTRICTION_COUNT } items (you have selected ${ checkedRoadshows.length } items)`
                  : 'Bulk Edit'
              }
              className={ styles.headerTitleActionsButton }
              iconType={ IconType.pencil }
              variant={ ActionButtonVariantTypes.icon }
              dataTest="bulkEditModalCall"
              onClick={ handleBulkEditingModal }
              isDisabled={ isDisabledBulkEditButton }
            />
          </div>
        ) }
      </div>
      <div className={ cn(cardStyles.cardHeaderActions, styles.headerActions) }>
        <ActionButton
          title="Export to Excel"
          iconType={ IconType.fileXls }
          variant={ ActionButtonVariantTypes.icon }
          dataTest="downloadXlsFile"
          isDisabled
        />
        { /* @ts-ignore */ }
        <Input
          name="search"
          formFieldClassName={ styles.searchInputWrapper }
          // @ts-ignore
          iconType={ IconType.filtering }
          placeholder="Filter by Company, Parent, Unique ID and Name"
          isNarrow
          dataTest="searchInput"
          value={ searchValue }
          onChange={ onSearchChange }
        />
      </div>
    </div>
  );
};

export default RoadshowsHeader;
