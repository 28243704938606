import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import cn from 'classnames';
import FinalForm from '@/ui/shared/components/Form/FinalForm';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import { Input, Spinner, Tooltip, StatusIndicator, StatusIndicatorType, IconType } from '@dealroadshow/uikit';
import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';
import DatePicker from '@/ui/shared/components/Form/DatePicker/DatePicker';
import { FULL_DATE_FORMAT } from '@/Framework/DateTime/dateFormats';
import { useDataroomCondorActionsContext } from '@/dataroom/application/condor/DataroomCondorActionsContext';
import { IDataroomDetails } from '@/dataroom/domain/vo/condor/IDataroomDetails';
import byteConverter from '@/Framework/dataHelpers/formatters/byteConverter';
import config from '@/dataroom/application/config/config';
import spacesStyles from '@/ui/shared/styles/helpers/spaces.scss';
import cardStyles from '@/ui/shared/styles/card.scss';
import styles from './dataroomForm.scss';
import validate from './validator';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';

interface IProps {
  tenant: DataroomTenant,
  dataroom: IDataroomDetails,
  isFetching: boolean,
}

const DataroomForm = ({
  tenant,
  isFetching,
  dataroom,
}: IProps) => {
  const history = useHistory();
  const { edit: { editDataroom, isFetching: isEditFetching } } = useDataroomCondorActionsContext();

  const { name: tenantName } = DataroomTenantConfig.fromCode(tenant);

  const handleCancel = () => {
    history.push(`/core/${ tenant }`);
  };

  const handleSubmit = (values) => editDataroom(dataroom.id, values)
    .then(() => {
      history.push(`/core/${ tenant }`);
    });

  const renderForm = useCallback((
    {
      submitting,
      valid,
      submitFailed,
      dirty,
    }, {
      Field,
    },
  ) => (
    <div>
      <div className={ cn(cardStyles.cardContainer, spacesStyles.mbn, styles.card) }>
        <Spinner
          isVisible={ submitting || isEditFetching }
          overlay
        />
        <div className={ styles.header }>
          <div className={ styles.headerText }>{ tenantName } Data Room Settings</div>
          <div>
            <Tooltip
              placement="bottom"
              content={ dataroom.isActive ? 'Live' : 'Deactivated' }
              className={ styles.hideTooltipOnMobile }
            >
              <StatusIndicator
                className={ styles.statusIndicator }
                status={ dataroom.isActive ? StatusIndicatorType.live : StatusIndicatorType.expired }
              />
            </Tooltip>
          </div>
        </div>
        <Field
          name="displayName"
          label="Data Room Name"
          component={ Input }
          dataTest="dataroomNameInput"
          className={ styles.wideInput }
        />
        <Field
          name="name"
          label="Data Room Url"
          component={ Input }
          dataTest="dataroomUrlInput"
          className={ styles.wideInput }
          disabled
        />
        { tenant === config.tenant.tenant17g5.code && (
          <Field
            name="defaultUploadingEmail"
            label="Email (for uploading files)"
            component={ Input }
            dataTest="dataroomEmailUploadingInput"
            className={ styles.wideInput }
            disabled
          />
        ) }
        <Field
          name="createdAt"
          component={ (fieldProps) => (
            // TODO: add attribute onChange
            // @ts-ignore
            <DatePicker
              customInput={ (
                <Input
                  /* @ts-ignore */
                  input={ { name: fieldProps.input.name } }
                  meta={ fieldProps.meta }
                  name={ fieldProps.input.name }
                  label="Date Created"
                  iconType={ IconType.calendar }
                  iconPosition="right"
                  dataTest="createdAtDateInput"
                  className={ styles.input }
                />
              ) }
              selected={ new Date(fieldProps.input.value) }
              dateFormat={ FULL_DATE_FORMAT }
              dataTest="createdAtDatePicker"
              disabled
            />
          ) }
        />
        <Field
          name="size"
          label="Actual Data Room Size"
          component={ Input }
          dataTest="sizeInput"
          className={ styles.input }
          isNarrow
          disabled
        />
      </div>
      <div className={ styles.createButtonWrp }>
        <Button
          type="submit"
          variant={ variantTypes.action }
          disabled={ (!valid && submitFailed) || !dirty || isEditFetching || submitting }
          title="Save"
          dataTest="submit"
        />
        <Button
          variant={ variantTypes.text }
          onClick={ handleCancel }
          disabled={ isEditFetching || submitting }
          dataTest="cancel"
          title="Cancel"
        />
      </div>
    </div>
  ), [isEditFetching]);

  return (
    <FinalForm
      initialValues={ {
        name: dataroom.name,
        displayName: dataroom.displayName,
        defaultUploadingEmail: dataroom.defaultUploadingEmail,
        createdAt: dataroom.createdAt,
        size: byteConverter(Number(dataroom.size)),
      } }
      onSubmit={ handleSubmit }
      render={ renderForm }
      isFetching={ isFetching }
      validate={ validate }
      name="editDataroomForm"
      dataTest="editDataroomForm"
      keepDirtyOnReinitialize
    />
  );
};

export default DataroomForm;
